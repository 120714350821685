.InputMedia {
  transition: opacity 0.2s ease-out;

  input[type="file"] {
    width: 0.0625rem;
    height: 0.0625rem;
    opacity: 0;
    overflow: hidden;
    position: absolute;
  }

  label {
    cursor: pointer;
  }

  .input-label {
    color: #006cff;
    text-align: center;
  }

  .input-preview {
    height: 150px;
    cursor: pointer;

    img {
      max-height: 100%;
    }
  }

  &.loading {
    opacity: 0.5;
  }
}
