@tailwind base;
@tailwind components;
@tailwind utilities;

@import "datepicker";

html {
  overflow-x: hidden;

  * {
    box-sizing: border-box;
  }

  .fidelidade-background {
    display: none;
  }

  &.generating-pdf,
  &.generating-pdf body {
    font-size: 13px !important;
    text-rendering: optimizeLegibility;
    opacity: 0;
    overflow: visible;
    padding: 0 !important;
    margin: 0 !important;
    zoom: 100%;
    height: 100vh;
    width: 100vw;

    .fidelidade-background {
      position: fixed;
      top: 400px;
      transform: translateX(-20%) translateY(-50%);
      z-index: -1;
      display: block;

      img {
        height: 500px;
      }
    }

    h1,
    h2,
    h3,
    h4,
    .h1,
    .h2,
    .h3,
    .h4 {
      width: 100%;
      border-bottom: 1px solid currentColor;
      margin-bottom: 0.5rem;
      padding: 0.25rem;
      padding-bottom: 0.5rem;
      font-size: 1.2rem;
      text-align: center;
      margin-top: 1rem;
    }

    .jumbotron {
      margin-bottom: 0;
    }

    .table {
      margin-top: 1rem;
    }

    .table th,
    .table td {
      padding: 0.25rem;
    }
  }

  .dialog-container .dialog-backdrop {
    background-color: transparent;
  }
  .dialog-container .dialog-item {
    backdrop-filter: blur(4px);
    background-color: rgba(255, 255, 255, 0.9);
  }
}

#page-content-wrapper {
  min-width: 100vw;
}

#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}

@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  #wrapper.toggled #sidebar-wrapper {
    margin-left: -20rem;
  }
}

@media (min-width: 992px) {
  .animate {
    animation-duration: 0.3s;
    -webkit-animation-duration: 0.3s;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0rem);
    opacity: 1;
  }
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
}

@-webkit-keyframes slideIn {
  0% {
    -webkit-transform: transform;
    -webkit-opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    -webkit-opacity: 1;
  }
  0% {
    -webkit-transform: translateY(1rem);
    -webkit-opacity: 0;
  }
}

.slideIn {
  -webkit-animation-name: slideIn;
  animation-name: slideIn;
}

li:hover > ul.dropdown-menu {
  display: block;
}
.dropdown-submenu {
  position: relative;
}
.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
}

/* rotate caret on hover */
.dropdown-menu > li > a:hover:after {
  text-decoration: underline;
  transform: rotate(-90deg);
}